.E0uxm {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    padding-left: 0px;
    padding-right: 0px;
    font-size: 1.5rem;
    font-weight: 500;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: -0.6px;
    --tw-text-opacity: 1;
    color: rgba(32, 32, 32, var(--tw-text-opacity));
    font-stretch: normal;
}

._5urNm .dduV1 ._3yhKL {
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.YErRf {
    padding-top: 0px;
}

.dduV1 ul {
    margin: 0px;
    display: flex;
    list-style-type: none;
    padding: 0px;
        flex-flow: column nowrap;
}

.dduV1 ul.UWFF3 {
    max-height: 0px;
    overflow: hidden;
    opacity: 0;
            transition: all 0.25s ease-in-out;
}

.dduV1 ul.UWFF3.s75tg {
    max-height: 600px;
    padding-bottom: 1rem;
    opacity: 1;
}

.dduV1 ul.UWFF3 ._3yhKL {
    padding-bottom: 0px;
    padding-top: 0px;
}

.dduV1 ul.UWFF3 .VYRSq {
    line-height: 2.5;
}

.dduV1 ul ._3yhKL {
    display: flex;
    padding: 0.75rem;
}

@media (min-width: 80em) {

    .dduV1 ul ._3yhKL {
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

.dduV1 ul .LElep {
    flex-basis: 2rem !important;
}

.dduV1 ul .LElep {
    align-self: center;
    --tw-text-opacity: 1;
    color: rgba(102, 102, 102, var(--tw-text-opacity));
            flex: 0 0 40px;
            transition: all 0.25s ease-in-out;
}

.dduV1 ul ._7ckuG {
    align-self: flex-start;
            flex: 0 0 auto;
}

.dduV1 ul .ja-nU {
    display: flex;
    flex: 1 1 0%;
    justify-content: center;
    padding-left: 0.5rem;
}

@media (min-width: 80em) {

    .dduV1 ul .ja-nU {
        padding-left: 1rem;
    }
}

.dduV1 ul .ja-nU {
            flex-flow: column nowrap;
        }

.dduV1 ul .VYRSq {
    display: flex;
    flex-direction: row;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;
    --tw-text-opacity: 1;
    color: rgba(32, 32, 32, var(--tw-text-opacity));
}

.dduV1 ul .SDW2Q {
    margin-top: -3px;
    padding-left: 0.25rem;
}

.dduV1 ul .xUiHn {
    position: absolute;
    right: 13px;
    font-size: 10px;
    --tw-text-opacity: 1;
    color: rgba(231, 19, 36, var(--tw-text-opacity));
}

.dduV1 ul .xUiHn span {
    font-size: 0.75rem;
}

.dduV1 ul .WGfpH {
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.33;
    --tw-text-opacity: 1;
    color: rgba(102, 102, 102, var(--tw-text-opacity));
}

.dduV1 ul .axOBL {
    border-bottom-width: 1px;
    border-style: solid;
    --tw-border-opacity: 1;
    border-color: rgba(216, 216, 216, var(--tw-border-opacity));
}

.dduV1 ul .axOBL.eblAu {
    border-width: 0px;
}

.dduV1 ul .axOBL.EfuS7 .LElep, .dduV1 ul .axOBL.lSeYK .LElep {
    --tw-text-opacity: 1;
    color: rgba(32, 32, 32, var(--tw-text-opacity));
    opacity: 1;
}

.dduV1 ul .axOBL:not(.EfuS7).dduV1 ul .axOBL:not(.lSeYK).dduV1 ul .axOBL:hover .LElep {
    opacity: 1;
}

/* to apply hover effect only in the device its supported well */

@media (hover: none) {

.dduV1 ul .axOBL:not(.EfuS7).dduV1 ul .axOBL:not(.lSeYK).dduV1 ul .axOBL:hover .LElep {
                        opacity: 0.199
                }
                    }

.dduV1 ul .axOBL.EfuS7 {
    position: relative;
}

.dduV1 ul .axOBL.EfuS7 .VYRSq {
    font-weight: 600;
    --tw-text-opacity: 1;
    color: rgba(32, 32, 32, var(--tw-text-opacity));
}

.dduV1 ul .lR-5h, .dduV1 ul .Nh4W4 {
    width: 100%;
    cursor: pointer;
    text-align: left;
}

.dduV1 ul .PuvUL {
            transition: transform .25s ease-in-out;
        }

.dduV1 ul .PuvUL.s75tg {
                transform: rotate(46deg);
            }
