.El994 {

    margin-left: 1rem !important;

    height: 2rem !important
}

    .El994 {

    margin-top: 0.25rem
}

    .El994 p {

    --tw-text-opacity: 1 !important;

    color: rgba(163, 32, 38, var(--tw-text-opacity)) !important
}
