.uzJCK .rJjts {
                overflow: hidden;
                border-radius: 0.5rem;
                --tw-bg-opacity: 1;
                background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
                padding-bottom: 7px;
}
    @media (max-width: 47.999em) {
                .uzJCK ._6XLtB .BtN4z {
                                width: 53%;
                }
}
    .uzJCK ._6XLtB .BtN4z .-WXSQ {
                border: inherit;
            }
    .uzJCK ._6XLtB p {
                --tw-text-opacity: 1;
                color: rgba(32, 32, 32, var(--tw-text-opacity));
}
    .uzJCK ._6XLtB .AiORQ {
                top: 0px;
                left: 5px;
                display: inline-block;
}
    .uzJCK ._6XLtB .uLgxg {
                display: inline-block;
                --tw-text-opacity: 1;
                color: rgba(32, 32, 32, var(--tw-text-opacity));
                text-decoration-line: underline;
}
    .uzJCK ._6XLtB ._0I0Ib {
                left: 0px;
                top: -0.625rem;
                height: 43px;
                width: 3rem;
}
    .uzJCK ._6XLtB .yMYFb {
                display: inline-block;
                padding-left: 0.75rem;
}
    @media (min-width: 80em) {
                .uzJCK ._6XLtB .yMYFb {
                                padding-left: 15px;
                }
}
    .uzJCK ._6XLtB .Zpvob p {
                font-size: 1rem;
}
    @media (max-width: 47.999em) {
                .uzJCK ._6XLtB .Zpvob p {
                                font-size: 0.875rem;
                                line-height: 1.25rem;
                }
}
    .uzJCK ._6XLtB .Zpvob .nU8D- {
                width: auto;
}
    /* some overrides for sliderBanner */
    @media (min-width: 48em) {
                .uzJCK._7a6xx ._6XLtB #_8nhxW {
                                width: 100%;
                }
                .uzJCK._7a6xx ._6XLtB #le3Wp {
                                width: 100%;
                }
                .uzJCK._7a6xx ._6XLtB #Zpvob {
                                width: 100%;
                }
}
    /* we are only applying media specific styles when banner is inPage type */
    @media (min-width: 80em) {
                .uzJCK.qYVNa {
                                margin-bottom: 15px;
                                border-radius: 10px;
                                padding: 1.25rem;
                }
}
    .uzJCK.qYVNa ._6XLtB {
                border-radius: 8px;
                border-style: solid;
                --tw-border-opacity: 1;
                border-color: rgba(102, 102, 102, var(--tw-border-opacity));
}
    @media (min-width: 80em) {
                .uzJCK.qYVNa ._6XLtB {
                                text-align: left;
                }
}
    .uzJCK.qYVNa ._6XLtB ._8nhxW {
                padding-left: 0px;
                padding-right: 0px;
}
    @media (min-width: 80em) {
                .uzJCK.qYVNa ._6XLtB ._8nhxW {
                                padding-top: 1.25rem;
                                padding-bottom: 1.25rem;
                }
                .uzJCK.qYVNa ._6XLtB ._8nhxW img {
                                max-width: none;
                }
                .uzJCK.qYVNa ._6XLtB .le3Wp {
                                padding-left: 50px;
                }
                .uzJCK.qYVNa ._6XLtB .le3Wp .AbW-z {
                                font-size: 32px;
                }
                .uzJCK.qYVNa ._6XLtB .le3Wp .vo9dJ {
                                font-size: 1.25rem;
                                line-height: 2rem;
                }
                .uzJCK.qYVNa ._6XLtB .Zpvob {
                                text-align: right;
                }
}
