.NjeY2 {
  font-size: 0.75rem;
  line-height: 1rem;
  margin-left: 0.25rem;
  --tw-text-opacity: 1;
  color: rgba(163, 32, 38, var(--tw-text-opacity))
}

._2VOHd:disabled {
  pointer-events: none
}

.PYDz-, .PYDz-:before, .PYDz-:after {
  height: 64px;
  width: 1rem;
  --tw-bg-opacity: 1;
  background-color: rgba(77, 77, 77, var(--tw-bg-opacity));
  animation: x-Y5j 1s infinite ease-in-out
}

.PYDz- {
  position: relative;
  margin: 1.5rem;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  font-size: 2px;
  --tw-text-opacity: 1;
  color: rgba(77, 77, 77, var(--tw-text-opacity));
  text-indent: -9999em;
  transform: translateZ(0) translateY(2px);
  animation-delay: -0.16s
}

.PYDz-:before, .PYDz-:after {
  position: absolute;
  top: 0px;
  content: ''
}

.PYDz-:before {
  left: -2rem;
  animation-delay: -0.32s
}

.PYDz-:after {
  left: 2rem;
  left: 2em
}

@keyframes x-Y5j {
  0%, 80%, 100% {
    height: 64px;
    box-shadow: 0 0
  }
  40% {
    height: 80px;
    box-shadow: 0 -2em
  }
}
